
import FullCal from "@/components/FullCal.vue";
import {defineComponent, ref} from "vue";
import {CallCalendarEvent} from "@/models/scheduling/CallCalendarEvent";
import {CallService} from "@/services/CallService";
import {bus} from "@/modules/eventBus";
import ScheduleWidget from "@/components/Calendar/ScheduleWidget.vue";
import {getColorName} from "@/models/scheduling/CallType";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";

export default defineComponent({
  setup() {
    const calendar = ref<InstanceType<typeof FullCal>>();
    const reFetchEvents = () => {
      calendar.value?.redraw();
    }
    const userStore = useUser();
    const applicationStore = useApplication();
    const toast = useToast();
    return {calendar, reFetchEvents, userStore, applicationStore, toast}
  },
  components: {FullCal, ScheduleWidget},
  data() {
    return {
      calls: [] as Array<CallCalendarEvent>,
      conflicts: [] as Array<CallCalendarEvent>,
      filterChanged: false,
      conflictFilterChanged: false,
      filters: {
        callTypes: [] as Array<string>,
        showFor: '',
        showConflicts: true,
        productions: [] as Array<string>
      },
    }
  },
  methods: {
    fetch(fetchInfo, successCallback, failureCallback) {
      this.$log.debug('fetch')
      if (this.filterChanged) {
        this.$log.debug('filterChanged')
        this.filterChanged = false
        let callTypeSet = new Set(this.filters.callTypes)
        let productionsSet = new Set(this.filters.productions)
        this.$log.debug('productionsSet', productionsSet)
        successCallback(this.calls.filter(call => {
          if(this.isOrganization && productionsSet.has(call.productionId)){
            this.$log.debug('Allowed Production', call)
            return call;
          }
          this.$log.debug('Not production', call)
          if (!this.isOrganization && callTypeSet.has(call.callTypeId) || (this.filters.showConflicts && call.isConflict)) {
            this.$log.debug('Allowed elsewhere', call)
            return call
          }
          this.$log.debug('Not allowed', call)
        }))
      } else {
        this.$log.debug('NO filterChanged')
        if (this.isOrganization) {
          CallService.fetchOrganizationCalls(this.organizationId, fetchInfo.startStr, fetchInfo.endStr).then(response => {
            this.calls = response.data
            this.calls.forEach(call => {
              call.classNames = `fc-h-${this.getCallTypeColor(call.callTypeColor)}-event`
            })
            this.$log.debug('Calls', response.data)
            successCallback(response.data)
            this.emitCalls()
          }).catch((err) => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
            failureCallback(err)
          })
        } else {
          this.$log.debug('isProduction')
          CallService.fetchProductionCalls(this.productionId, fetchInfo.startStr, fetchInfo.endStr).then(response => {
            this.calls = response.data
            this.calls.forEach(call => {
              call.classNames = `fc-h-${this.getCallTypeColor(call.callTypeColor)}-event`
            })
            this.$log.debug('fetchProductionCalls')
            successCallback(response.data)
            this.emitCalls()
          }).catch((err) => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
            failureCallback(err)
          })
        }
      }
    },
    fetchConflicts(fetchInfo, successCallback, failureCallback) {
      if(!this.isOrganization){
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          if (this.conflictFilterChanged) {
            this.conflictFilterChanged = false
            successCallback([...new Map(this.conflicts.map(item =>
                [item['conflictId'], item])).values()].filter(conflict => {
              if (conflict.isConflict && this.filters.showConflicts) {
                return conflict
              }
            }))
          } else {
            CallService.fetchProductionConflicts(this.productionId, fetchInfo.startStr, fetchInfo.endStr).then(response => {
              this.conflicts = response.data
              this.conflicts.forEach(conflict => {
                conflict.display = 'block'
              })
              successCallback([...new Map(response.data.map(item =>
                  [item['conflictId'], item])).values()])
              this.emitConflicts()
            }).catch((err) => {
              let errorDetails = err.response.data as ProblemDetails
              this.toast.error(errorDetails.detail)
              failureCallback(err)
            })
          }
        } else {
          successCallback([])
        }
      } else {
        successCallback([])
      }
    },
    emitCalls() {
      bus.emit('calendar:filters:update', this.calls)
    },
    emitConflicts() {
      bus.emit('calendar:filters:update:conflicts', this.conflicts)
    },
    getCallTypeColor(color: number) {
      return getColorName(color);
    },
    invokeAddConflict() {
      bus.emit('modal:conflicts:add')
    },
  },
  computed: {
    eventsNow(): Array<CallCalendarEvent> {
      return this.calls
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
  },
  mounted() {
    bus.on('modal:scheduling:added', () => {
      this.reFetchEvents()
    })
    bus.on('modal:scheduling:updated', () => {
      this.reFetchEvents()
    })
    bus.on('calendar:filters:change', (filters) => {
      // console.log('filters changed scheduling tab', filters)
      this.$log.debug('calendar:filters:change', filters)
      this.filters = filters as any
      this.filterChanged = true
      this.reFetchEvents()
    })
    bus.on('calendar:filters:change:conflicts', (showConflicts) => {
      // console.log('conflicts filter changed scheduling tab', showConflicts)
      this.conflictFilterChanged = true
      this.filters.showConflicts = showConflicts as boolean
      this.reFetchEvents()
    })
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.reFetchEvents()
          }
        }
    )
  },
})
